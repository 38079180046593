<template lang="">
  <div class="w-full">
    <div
      v-if="jobs.length > 0"
    >
      <div
        v-for="(job, i) in jobs"
        :key="i"
        class="w-full"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-anchor-placement="top-bottom"
      >
        <!-- pc/tablet -->
        <div
          v-if="$screen.breakpoint !== 'mobile'"
          class="flex justify-between py-[28px] border-b-[1px] border-gray-200 w-full"
        >
          <div class="flex items-center ">
            <div
              class="
                    text-[20px]
                    text-gray-800 font-bold
                    hover:text-orange-500
                    cursor-pointer
                    mr-[4px]
                  "
              @click="$router.push(`/careers/jobDetail/${job.id}`)"
            >
              <!-- {{ job.alias }} -->
              <span v-html="styledKeyword(job.alias)" />
            </div>
            <div class="flex">
              <div
                v-if="job.emergency"
                class="pc:ml-[8px]"
              >
                <el-tooltip
                  :visible-arrow="false"
                  content="긴급 채용 직무입니다."
                  placement="bottom"
                  effect="dark"
                >
                  <div>
                    🚨
                  </div>
                </el-tooltip>
              </div>
                  
              <div 
                v-if="job.rank"
                class="
                      ml-[8px] h-[24px] py-[3px] text-[14px] tracking-[-0.1px]
                      px-[6px] bg-orange-500 text-white font-bold leading-[1.27] rounded-[4px] 
                    "
              >
                {{ job.rank }}
              </div>
              <!-- <div 
                v-if="job.employType === '정규직'"
                class="
                      ml-[8px] h-[24px] py-[3px] text-[14px] tracking-[-0.1px] w-[71px]
                      px-[6px] border-orange-500 text-orange-500 font-bold leading-[1.27] border-[1px] rounded-[4px] 
                    "
              >
                THE 100
              </div> -->
              <!-- v-if="(job.jobTypeId === 6 && new Date('2023-02-06').getTime() <= Date.now() && new Date('2023-02-20').getTime() > Date.now()) || (job.jobTypeId === 7 && new Date('2023-02-06').getTime() <= Date.now() && new Date('2023-02-20').getTime() > Date.now())" -->
              <div 
                v-if="job.jobTypeId === 0"
                class="
                      ml-[8px] h-[24px] py-[3px] text-[14px] tracking-[-0.1px] w-[66px]
                      px-[6px] border-orange-500 text-orange-500 font-bold leading-[1.27] border-[1px] rounded-[4px] 
                    "
              >
                집중 채용
              </div>
            </div>
            <div class="flex items-center pc:hidden tablet:hidden">
              <div class="w-[110px] text-gray-500 text-left text-[14px] leading-[1.29] tracking-[-0.1px] mt-[10px]">
                {{ job.yearsType }} · {{ job.employType }}
              </div>
            </div>
          </div>
          <div class="flex mr-[32px] text-gray-500 pc:inline-flex  mobile:hidden">
            <div class="w-[143px] truncate mr-[48px] text-left">
              {{ job.yearsType }} · {{ job.employType }}
            </div>
            <div class="w-[160px] truncate text-left">
              {{ category[job.jobTypeId] }}
            </div>
          </div>
        </div>

        <!-- mobile -->
        <div
          v-else
          class="flex flex-col py-[28px]  border-b-[1px] border-gray-200 w-full"
        >
          <div>
            <p class="">
              <span
                class="text-[18px] font-bold text-gray-800 leading-[1.27] tracking-[-0.1px] mr-[4px] align-text-top hover:text-orange-500"
                @click="$router.push(`/careers/jobDetail/${job.id}`)"
              >
                <!-- {{ job.alias }} -->
                <span v-html="styledKeyword(job.alias)" />
              </span>
              <span class="inline-flex items-center">
                <div 
                  v-if="job.rank"
                  class="
                    mobile:mr-[6px] mobile:h-[18px] mobile:py-[2px] mobile:text-[11px] mobile:tracking-[-0.05px] align-text-bottom
                    tablet:mr-[6px] tablet:h-[18px] tablet:py-[2px] tablet:text-[14px] tablet:tracking-[-0.1px]
                    px-[6px] bg-orange-500 text-white font-bold leading-[1.27] rounded-[4px] 
                  "
                >
                  {{ job.rank }}
                </div>
              </span>
              <!-- <span class="inline-flex items-center h-full">
                <div 
                  v-if="job.employType === '정규직'"
                  class="
                    mobile:mr-[6px] mobile:h-[18px] mobile:py-[2px] mobile:text-[11px] mobile:tracking-[-0.05px] mobile:w-[58px] align-text-bottom
                    tablet:mr-[6px] tablet:h-[18px] tablet:py-[2px] tablet:text-[14px] tablet:tracking-[-0.1px] tablet:w-[67px] align-text-bottom
                    px-[6px] border-orange-500 text-orange-500 font-bold leading-[1.27] border-[1px] rounded-[4px] 
                  "
                >
                  THE 100
                </div>
              </span> -->
              <span class="inline-flex items-center h-full">
                <!-- v-if="(job.jobTypeId === 6 && new Date('2023-02-06').getTime() <= Date.now() && new Date('2023-02-20').getTime() > Date.now()) || (job.jobTypeId === 7 && new Date('2023-02-06').getTime() <= Date.now() && new Date('2023-02-20').getTime() > Date.now())" -->
                <div 
                  v-if="job.jobTypeId === 0"
                  class="
                    mobile:mr-[6px] mobile:h-[18px] mobile:py-[2px] mobile:text-[11px] mobile:tracking-[-0.05px] mobile:w-[55px] align-text-bottom
                    tablet:mr-[6px] tablet:h-[18px] tablet:py-[2px] tablet:text-[14px] tablet:tracking-[-0.1px] tablet:w-[67px] align-text-bottom
                    px-[6px] border-orange-500 text-orange-500 font-bold leading-[1.27] border-[1px] rounded-[4px] 
                  "
                >
                  집중 채용
                </div>
              </span>
            </p>
          </div>
          <div class="flex items-center text-gray-500 text-left text-[14px] leading-[1.29] tracking-[-0.1px] mt-[10px]">
            <div class="">
              {{ job.yearsType }} · {{ job.employType }}
            </div>
            <div
              class="pc:ml-[8px] mobile:ml-[4px]"
            >
              <el-tooltip
                v-if="job.emergency"
                :visible-arrow="false"
                content="긴급 채용 직무입니다."
                placement="bottom"
                effect="dark"
              >
                <div>
                  · 🚨 긴급
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="w-full font-Pretendard"
    >
      <div class="relative text-center mt-[164px] mb-[188px]">
        <i class="icon-cook2 text-[100px] text-gray-200" />
        <div 
          class="text-3xl text-[#bebebe] mt-4"
        >
          검색 결과가 없습니다.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'Aaa',
  props: {
    jobs: {
      type: Array,
      default() {
        return {};
      },
    },
    keyword: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      category: {
        1: '게임기획/시나리오',
        2: '데이터 분석',
        3: '사업/마케팅',
        4: '아트/디자인',
        5: '인공지능(AI)',
        6: '프로그래밍',
        7: '전문연구요원',
        8: '경영지원',
        11: '고객관리',
        12: '인사/조직문화',
        13: '영상/미디어',
        14: '프리랜서',
        15: '관계사',
        16: '인턴',
        19: '시나리오',
        21: '비웨일즈(관계사)',
      }
    };
  },
  methods: {
    styledKeyword(jobAlias) {
      const result = jobAlias.replace(this.keyword, `<span style="color: #ff7f00">${this.keyword}</span>`);
      return result;
    }
  },
};
</script>
<style>
.el-tooltip__popper.is-dark {
  background: #444444;
  font-family: Pretendard;
  font-size: 13px;
  height: 26px;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
}
</style>