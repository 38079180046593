<template>
  <div
    class="
    flex justify-center 
    pc:pt-[96px]    
    tablet:pt-[72px] tablet:mx-[32px]
    mobile:pt-[40px] mobile:mx-[16px]
  "
  >
    <div class="flex flex-col items-start font-Pretendard max-w-[1063px] w-full">
      <div 
        class="
          border-b-[1px] border-black
          mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px]
          w-full font-bold
        "
        data-aos="fade-up"
        data-aos-duration="900"
      >
        <div
          class="
          flex items-center 
          pc:mb-[25px] pc:text-[44px] pc:leading-[1.27] pc:tracking-[-0.4px]
          tablet:mb-[16px] tablet:text-[30px] tablet:leading-[38px] tablet:tracking-[-0.3px]
          mobile:mb-[16px] mobile:text-[26px] mobile:leading-[32px] mobile:tracking-[-0.2px]
        "
        >
          <div>
            채용 중인 포지션
          </div>
          <div
            class="
              pc:ml-[12px] pc:rounded-[21px] pc:h-[34px] pc:w-[49px] pc:text-[20px]
              mobile:ml-[6px] mobile:rounded-[14px] mobile:h-[22px] mobile:w-[34px] mobile:text-[14px]
              flex justify-center items-center text-orange-500 bg-orange-50 
            "
          >
            {{ count }}
          </div>
        </div>
      </div>
      <JobList :jobs="jobs" />
      <div class="flex justify-center w-full pt-[48px] pb-[128px] ">
        <CustomButton
          class="
            pc:mt-[40px]
            tablet:mt-[24px]
            mobile:mt-[24px]
            font-Pretendard
          "
          :text="`${count}개 직무 전체 보기`"
          :size="$screen.breakpoint === 'pc' ? '2xl' : 'xl'"
          rounded
          color="secondary"
          data-aos="fade-up"
          data-aos-duration="900"
          @click.native="$router.push('/careers/list')"
        />
      </div>
    </div>
  </div>
</template> 
<script> 
import { getOpenJobs } from '@/api/job';
import CustomButton from '../../../components/Button/customButton.vue';
import JobList from '../../../components/careers/jobList.vue';

export default {
  components: { CustomButton, JobList },
  data() {
    return {
      count: 0,
      jobs: [],
      cookappsForFun: 'CookApps for FUN!',
      category: {
        1: '게임기획/시나리오',
        2: '데이터 분석',
        3: '사업/마케팅',
        4: '아트',
        5: '인공지능(AI)',
        6: '프로그래밍',
        7: '전문연구요원',
        8: '경영지원',
        11: '고객관리',
        12: '인사/조직문화',
        13: '영상/미디어',
        14: '프리랜서',
        15: '관계사',
        16: '인턴',
        19: '시나리오',
        21: '비웨일즈(관계사)'
      },
      idxCategory: [
        // {
        //   idx: 0,
        //   label: '게임기획/시나리오',
        //   id: 1,
        // },
        // {
        //   idx: 1,
        //   label: '아트',
        //   id: 4,
        // },
        // {
        //   idx: 2,
        //   label: '프로그래밍',
        //   id: 6,
        // },
        // {
        //   idx: 3,
        //   label: '사업/마케팅',
        //   id: 3,
        // },
        // {
        //   idx: 4,
        //   label: '고객관리',
        //   id: 11,
        // },
        // {
        //   idx: 5,
        //   label: '인사/조직문화',
        //   id: 12,
        // },
        // {
        //   idx: 6,
        //   label: '비디오/미디어',
        //   id: 13,
        // },
        // {
        //   idx: 7,
        //   label: '데이터 분석',
        //   id: 2,
        // },
        // {
        //   idx: 8,
        //   label: '전문연구요원',
        //   id: 7,
        // },
        // {
        //   idx: 9,
        //   label: '인턴',
        //   id: 16,
        // },
        // {
        //   idx: 10,
        //   label: '프리랜서',
        //   id: 14,
        // },
        // {
        //   idx: 11,
        //   label: '관계사',
        //   id: 15,
        // },
        // {
        //   idx: 12,
        //   label: 'REMOTE',
        //   id: 20,
        // },
        // {
        //   idx: 13,
        //   label: '비웨일즈(관계사)',
        //   id: 21,
        // },
        {
          idx: 0,
          name: 'jobType',
          label: '인턴',
          value: 16,
          id: 16,
        },
        {
          idx: 1,
          name: 'jobType',
          label: '게임기획/시나리오',
          value: 1,
          id: 1,
        },
        {
          idx: 2,
          name: 'jobType',
          label: '아트/디자인',
          value: 4,
          id: 4,
        },
        {
          idx: 3,
          name: 'jobType',
          label: '프로그래밍',
          value: 6,
          id: 6,
        },
        {
          idx: 4,
          name: 'jobType',
          label: '사업/마케팅',
          value: 3,
          id: 3,
        },
        {
          idx: 5,
          name: 'jobType',
          label: '경영지원',
          value: 8,
          id: 8,
        },
        {
          idx: 6,
          name: 'jobType',
          label: '고객관리',
          value: 11,
          id: 11,
        },
        {
          idx: 7,
          name: 'jobType',
          label: '인사/조직문화',
          value: 12,
          id: 12,
        },
        {
          idx: 8,
          name: 'jobType',
          label: '비디오/미디어',
          value: 13,
          id: 13,
        },
        {
          idx: 9,
          name: 'jobType',
          label: '데이터 분석',
          value: 2,
          id: 2,
        },
        {
          idx: 10,
          name: 'jobType',
          label: '전문연구요원',
          value: 7,
          id: 7,
        },
        {
          idx: 11,
          name: 'jobType',
          label: '프리랜서',
          value: 14,
          id: 14,
        },
        {
          idx: 12,
          name: 'jobType',
          label: '관계사',
          value: 15,
          id: 15,
        },
        {
          idx: 13,
          name: 'jobType',
          label: 'REMOTE',
          value: 20,
          id: 20,
        },
        {
          idx: 14,
          name: 'jobType',
          label: '비웨일즈(관계사)',
          value: 21,
          id: 21,
        }
      ],
    };
  },
  async created() {
    const items = (await getOpenJobs()).data.items;
    this.count = items.length;
    items.sort(this.sortKorean);
    items.map(item => { // index에 따라서 순서 소팅
      const order = this.idxCategory.find(type => String(type.id) === String(item.jobTypeId));
      item.order = order.idx;
      // if (item.id === 380) {
      //   item.order = 2.1;
      // } else if (item.id === 312) {
      //   item.order = 2.3;
      // } else {
      //   item.order = order.idx;
      // }
    });
    items.sort(function(a, b) {
      return a.order - b.order;
    });
    this.jobs = items.slice(0, 10);
  },
  methods: {
    sortKorean(a, b){
      if( a.alias > b.alias ) return 1;
      if( b.alias > a.alias ) return -1;
      return 0;
    },
  }
};
</script> 
