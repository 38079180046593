<template lang="">
  <div
    v-if="$screen.breakpoint == 'pc'"
    class="w-full flex flex-col justify-center items-center font-Pretendard border-b-[1px] border-gray-200"
  >
    <div
      ref="banner"
      class="flex justify-center items-center bg-center h-[500px] object-cover"
      :style="{ 
        'backgroundImage': `url('${require('@/assets/culture/image.png')}')`,
        'width': (676 + width) +'px'
      }"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        class="text-white w-[614px] text-[56px] font-bold leading-[1.25] tracking-[-0.51px] text-center"
        :style="'word-wrap: break-word; word-break: keep-all;'"
      >
        {{ text }}
      </div>
    </div>
    <div
      class="w-[737px] text-[30px] text-gray-700 font-bold leading-[1.57] tracking-[-0.3px] text-center mt-[200px] mb-[230px]  "
      :style="'word-wrap: break-word; word-break: keep-all;'"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      우리는 우리 스스로가 즐거워야 <br>
      재미있는 게임을 만들 수 있다고 믿습니다.<br>
      즐겁게 일할 때, 비로소 나 자신은 역량을 마음껏 발휘하고<br>
      우리는 서로가 지닌 끝없는 잠재력을 이끌어 낼 수 있습니다.<br>
      그래서 쿡앱스는 <span class="text-orange-500">게임 제작자의 즐거움</span>에 아낌없이 투자합니다.
    </div>
  </div>
  <div
    v-else
    class="flex flex-col px-[16px]"
  >
    <div
      class="text-[26px] leading-[36px] tracking-[-0.2px] font-bold text-gray-800"
      :style="'word-wrap: break-word; word-break: keep-all;'"
    >
      만드는 사람이 즐거워야 <span class="text-orange-500">재밌는 게임</span>이 나온다는 믿음
    </div>
    <div
      class="mt-[8px] text-gray-700 text-[16px] leading-[1.69] tracking-[0.1px]"
      :style="'word-wrap: break-word; word-break: keep-all;'"
    >
      우리는 우리 스스로가 즐거워야 재미있는 게임을 만들 수 있다고 믿습니다. 
      즐겁게 일할 때, 비로소 나 자신은 역량을 마음껏 발휘하고 
      우리는 서로가 지닌 끝없는 잠재력을 이끌어 낼 수 있습니다. 
      그래서 쿡앱스는 <span class="text-orange-500">게임 제작자의 즐거움</span>에 아낌없이 투자합니다.
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      width: 0,
      text: '만드는 사람이 즐거워야 재밌는 게임이 나온다는 믿음'
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const scrollY = window.top.scrollY;
      const bannerTop = this.$refs.banner.offsetTop - window.innerHeight + 200;
      // const show = scrollY >= bannerTop;
      // console.log(show);
      if(scrollY >= bannerTop){
        this.width = Math.min(-3.5 *(bannerTop - scrollY), window.innerWidth - 676) ;
      }
      else if (scrollY < bannerTop){
        this.width = 0;
      }
    }
  },
};
</script>
<style lang="">
  
</style>