<template>
  <div class="pc:py-[80px] tablet:py-[72px] mobile:py-[64px] bg-[rgba(245,245,245,0.7)] border-b-[1px] border-gray-200">
    <div class="flex flex-col items-center font-Pretendard">
      <div 
        class="
          pc:text-[22px] pc:leading-[28px] pc:tracking-[-0.2px]
          tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px]
          mobile:text-[16px] mobile:leading-[20px] mobile:tracking-[-0.1px]
          text-orange-500 font-bold
        "
        data-aos="fade-up"
        data-aos-duration="900"
      >
        {{ cookappsForFun }}
      </div>
      <div
        class="
          pc:text-[44px] pc:leading-[56px] pc:tracking-[-0.4px] pc:mt-[10px] pc:w-[500px]
          tablet:text-[36px] tablet:leading-[52px] tablet:tracking-[-0.3px] tablet:mt-[8px] tablet:w-[400px]
          mobile:text-[26px] mobile:leading-[36px] mobile:tracking-[-0.2px] mobile:mt-[8px] mobile:w-[300px]
          text-gray-800 font-bold 
          text-center
        "
        :style="'word-wrap: break-word; word-break: keep-all;'"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        {{ title }}
      </div>
      <div
        class="
          pc:max-w-[628px] tablet:max-w-[560px] 
          pc:text-[20px] pc:leading-[34px] pc:tracking-[-0.2px] pc:mt-[16px]
          tablet:text-[18px] tablet:leading-[33px] tablet:tracking-[-0.1px] tablet:mt-[12px]
          mobile:text-[16px] mobile:leading-[27px] mobile:tracking-[-0.1px] mobile:mt-[8px] mobile:px-[16px]
          text-gray-700 text-center
        "
        :style="'word-wrap: break-word; word-break: keep-all;'"
        data-aos="fade-up"
        data-aos-duration="900"
      >
        {{ desc }}
      </div>
    </div>
  </div>
</template> 
<script> 
export default {
  data() {
    return {
      cookappsForFun: 'CookApps for FUN!',
      title: '오직 유저의 재미를 위해 더 작고 빠르게 시도합니다',
      desc: '우리는 유저가 재밌는 게임을 만들기 위해 모였습니다. 끊임없이 시도하며 유저의 재미를 찾아내고, 우리의 직관을 데이터로 검증하며 혼자서는 만들지 못할 성공적인 게임을 함께 제작합니다.'
    };
  }
};
</script> 
<style>
</style>