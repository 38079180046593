var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:[_vm.color, {outline: _vm.outline}],staticStyle:{"display":"flex"},style:([
    _vm.height,
    _vm.radius,
    _vm.padding
  ]),attrs:{"type":"button"}},[_c('span',{style:([
      _vm.fontSize,
      _vm.fontWeight,
      _vm.fontColor ])},[_vm._v(_vm._s(_vm.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }