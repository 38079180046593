<template lang="">
  <button
    type="button"
    class="button"
    :class="[color, {outline}]"
    style="display: flex; "
    :style="[
      height,
      radius,
      padding
    ]"
  >
    <span
      :style="[
        fontSize,
        fontWeight,
        fontColor,
      ]"
    >{{ text }}</span>
  </button>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Button',
      required: false
    },
    size: {
      type: String, 
      default: 'md', //xs, sm, md, lg, xl, 2xl
      required: false
    },
    color: {
      type: String, 
      default: 'primary',
      required: false
    },
    rounded: {
      type: Boolean, 
      default: false,
      required: false
    },
    outline: {
      type: Boolean, 
      default: false, 
      required: false
    },
    disabled: {
      type: Boolean, 
      default: false, 
      required: false
    },
  },
  data(){
    return {
      heights: {
        'xs': 36, 
        'sm': 40, 
        'md': 44, 
        'lg': 48, 
        'xl': 52, 
        '2xl': 64
      },
      fontSizes: {
        'xs': 16, 
        'sm': 16, 
        'md': 16, 
        'lg': 18, 
        'xl': 20, 
        '2xl': 24
      },
      paddingX: 40,
      paddingY: 0
    };
  },
  computed: {
    radius() {
      return {
        borderRadius: (this.rounded ? this.heights[this.size] / 2 : 4) + 'px'
      };
    },
    height() {
      return {
        height: this.heights[this.size] + 'px'
      };
    },
    padding() {
      return {
        paddingLeft:  this.paddingX + 'px',
        paddingRight:  this.paddingX + 'px',
        paddingTop:  this.paddingY + 'px',
        paddingBottom:  this.paddingY + 'px',
      };
    },
    fontWeight(){
      return {
        fontWeight: this.outline ? 'normal' : 'bold'
      };
    },
    fontColor(){
      return {
        color: this.outline ? '#666666' : 'white'
      };
    },
    fontSize(){
      return {
        fontSize: this.fontSizes[this.size] + 'px',
      };
    },
  }
};
</script>
<style scoped lang="scss">
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff9933;


    &.outline {
      outline: 0;
      background-color: white;
      border: solid 1px #e1e1e1;
      &:hover {
          background-color: #f5f5f5;
        }
        &:active {
          background-color: #efefef;
        }
        // &:focus {
        //   background-color: #ff7f00
        // }
        &:disabled {
          background-color: #efefef
        }
    }

    &:not(.outline) {
      &.primary {
        background-color: #ff9933;
        &:hover {
          background-color: #ff7f00;
        }
        &:active {
          background-color: #ff7f00;
        }
        // &:focus {
        //   background-color: #ff9933
        // }
        &:disabled {
          background-color: #ffcc99
        }
      }

      &.secondary{
        background-color: #ff7f00;
        &:hover {
          background-color: #db6201;
        }
        &:active {
          background-color: #db6201;
        }
        // &:focus {
        //   background-color: #ff7f00
        // }
        &:disabled {
          background-color: #ffb266
        }
      }
    }
  }
</style>