<template>
  <div 
    class="pc:pt-[96px]  tablet:pt-[72px] mobile:pt-[40px] flex flex-col justify-center items-center font-Pretendard"
  >
    <div
      class="
        section__common__container 
        max-w-[1064px]
        pc:px-0
        tablet:px-[32px]
        mobile:px-[16px]
      "
    >
      <div
        class="tablet:mb-[24px] mobile:mb-[8px]"
      >
        <!-- <div>
          <div 
            class="
              pc:text-[44px] pc:leading-[56px] pc:tracking-[-0.4px] pc:text-left 
              tablet:text-[36px] tablet:leading-[52px] tablet:tracking-[-0.3px] tablet:text-center 
              mobile:text-[26px] mobile:leading-[36px] mobile:tracking-[-0.2px] mobile:text-center
              font-bold
            "
          >
            당신을 위한,<br>
            아낌없는 <span class="text-orange-500">보상</span>과 <span class="text-orange-500">지원</span>
          </div>
          <div
            class="
              pc:text-[20px] pc:leading-[34px] pc:tracking-[-0.2px] pc:text-left pc:w-[630px] 
              tablet:text-[18px] tablet:leading-[33px] tablet:tracking-[-0.1px] tablet:text-center 
              mobile:text-[16px] mobile:leading-[27px] mobile:tracking-[-0.1px] mobile:text-center
            "
            :style="'word-wrap: break-word; word-break: keep-all;'"
          >
            가슴속에 애기 말 다 소녀들의 까닭이요, 까닭입니다. 써 차 새워 마리아 북간도에 많은 릴케 강아지, 봅니다. 겨울이 벌써 그리고 불러 때 강아지,가슴속에 애기 말 다 소녀들의 까닭이요, 까닭입니다.
          </div>
        </div> -->
        <div class="flex justify-start items-center">
          <div
            v-if="$screen.breakpoint === 'pc'"
            class="text-gray-800 font-bold text-[44px] leading-[1.27] tracking-[-0.4px]"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            쿡앱스의 <span class="text-orange-500">복리후생</span>
          </div>
        </div>
        <div 
          class="
            pc:overflow-hidden 
            tablet:overflow-hidden 
            mobile:overflow-x-scroll
            w-full
          "
        >
          <div
            class="
              grid
              pc:grid-cols-6 pc:gap-x-[20px] pc:gap-y-[56px] pc:mt-[48px] pc:w-[100%] pc:pb-[160px]
              tablet:grid-cols-4 tablet:gap-x-[20px] tablet:gap-y-[56px] tablet:mt-[56px] tablet:w-[100%] tablet:pb-[120px]
              mobile:grid-cols-6 mobile:gap-x-[20px] mobile:gap-y-[32px] mobile:w-[900px] mobile:pb-[80px]
            "
          >
            <div
              v-for="(d, i) in swipeData"
              :key="i"
              class="w-[100%]"
              data-aos="fade-up"
              data-aos-duration="900"
            >
              <div class="pt-[100%] bg-[rgba(255,242,229,0.4)] rounded-[100%] flex items-center justify-center">
                <img
                  class="mt-[-100%] w-[45%]"
                  :src="require(`@/assets/culture/${d.icon}.png`)"
                >
              </div>
              <div
                class="
                  pc:mt-[16px] pc:text-[18px] pc:leading-[24px] pc:tracking-[-0.1px]
                  tablet:mt-[16px] tablet:text-[18px] tablet:leading-[24px] tablet:tracking-[-0.1px]
                  mobile:mt-[12px] mobile:text-[16px] mobile:leading-[22px] mobile:tracking-[-0.1px]
                  text-center
                "
                v-html="d.desc"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script> 
export default {
  data() {
    return {
      swipeData: [{
        icon: 'icon-01',
        desc: '주 35시간 근무,<br>점심시간 2시간'
      }, {
        icon: 'icon-11',
        desc: '자유로운 출퇴근<br>유연근무제'
      }, {
        icon: 'icon-03',
        desc: '대학/대학원 학비<br>90% 지원'
      }, {
        icon: 'icon-12',
        desc: '어학 및 자기계발<br>교육비 지원'
      }, {
        icon: 'icon-04',
        desc: '연간 유급휴가<br>21일 지급'
      }, {
        icon: 'icon-07',
        desc: '장기 근속 휴가 및<br>포상금 지급'
      }, {
        icon: 'icon-09',
        desc: '연 2회 연봉 협상 및<br>인센티브 지급'
      }, {
        icon: 'icon-06',
        desc: '연간 복지포인트<br>120만원'
      }, {
        icon: 'icon-08',
        desc: '사내 마사지실<br>운영'
      }, {
        icon: 'icon-05',
        desc: '사내 심리상담<br>운영'
      }, {
        icon: 'icon-02',
        desc: '아침, 점심, 저녁<br>제공'
      }, {
        icon: 'icon-10',
        desc: '다양한 사내 동아리<br>활동 지원'
      },]
    };
  },
  methods: {

  },
};
</script> 
<style>
</style>