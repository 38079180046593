<template>
  <div 
    class="pc:pt-[80px] pc:pb-[250px] tablet:py-[72px] mobile:pt-[64px] mobile:pb-[48px] flex flex-col justify-center items-center font-Pretendard select-none"
    data-aos="fade-up"
    data-aos-duration="900"
  >
    <div
      class="section__common__container !max-w-[1064px]"
    >
      <div v-if="$screen.breakpoint === 'pc'">
        <swiper
          :options="workSwiperOption"
          class="w-full"
        >
          <swiper-slide
            v-for="(d, i) in swipeData"
          
            :key="i"
          >
            <div 
              class="
              pc:grid pc:grid-cols-10 gap-[24px] pc:h-[136px]
            "
            >
              <div 
                class="
                pc:col-start-1 pc:col-span-3
                pc:text-[44px] pc:leading-[56px] pc:tracking-[-0.4px] 
                font-bold
              "
                :style="'word-wrap: break-word; word-break: keep-all;'"
                v-html="d.title"
              />
              <div
                class="
                pc:col-start-4 pc:col-span-7
                pc:text-[20px] pc:leading-[34px] pc:tracking-[-0.2px] pc:text-left
                text-gray-700
              "
                :style="'word-wrap: break-word; word-break: keep-all;'"
              >
                {{ d.desc }}
              </div>
            </div>
            <div
            
              class="
                mt-[56px]
                grid grid-cols-10 gap-[24px]
              "
            >
              <div 
                :class="
                  i % 2 === 1 ? 'col-start-1 col-span-7' : 'col-start-1 col-span-3'
                "
              >
                <img
                  class="pointer-events-none"
                  :src="require(`@/assets/culture/image-${ 2 * i + 1 }.png`)"
                  draggable="false"
                >
              </div>
              <div 
                :class="
                  i % 2 === 1 ? 'col-start-8 col-span-3' : 'col-start-4 col-span-7'
                "
              >
                <img
                  :src="require(`@/assets/culture/image-${ 2 * i + 2 }.png`)"
                >
              </div>
            </div>
          </swiper-slide>
          <div
            slot="pagination"
            class="swiper-pagination"
          />
        </swiper>
      </div>
      <div
        v-else
        class="tablet:mb-[24px] mobile:mb-[8px] border-b-[1px] border-gray-200"
      >
        <div
          v-for="(d, i) in swipeData"
          :key="i"
        >
          <div 
            class=" tablet:flex tablet:flex-col tablet:items-center mobile:flex mobile:flex-col mobile:items-center"
          >
            <div 
              class="
                tablet:text-[30px] tablet:leading-[38px] tablet:tracking-[-0.3px]
                mobile:text-[22px] mobile:leading-[28px] mobile:tracking-[-0.2px]
                font-bold
              "
              :style="'word-wrap: break-word; word-break: keep-all;'"
              v-html="d.title"
            />
            <div
              class="
                tablet:text-[18px] tablet:leading-[33px] tablet:tracking-[-0.1px] tablet:mt-[8px] tablet:max-w-[520px] tablet:text-center
                mobile:text-[16px] mobile:leading-[27px] mobile:tracking-[-0.1px] mobile:mt-[8px] mobile:px-[16px] mobile:text-center
                text-gray-700
              "
              :style="'word-wrap: break-word; word-break: keep-all;'"
            >
              {{ d.desc }}
            </div>
          </div>
          <img
            class="w-full tablet:mb-[80px] mobile:mb-[64px]"
            :class="$screen.breakpoint === 'tablet' ? 'mt-[64px]' : 'mt-[56px]'"
            :src="require(`@/assets/culture/image-${ 2 * i + (i % 2 === 1 ? 1 : 2) }.png`)"
          >
        </div>
      </div>
      <div
        
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />
    </div>
  </div>
</template> 
<script> 
export default {
  data() {
    return {
      workSwiperOption: {
        slidesPerView: 1,
        preventClicks: true,
        preventClicksPropagation: true,
        // observer: true,
        // observeParents: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swipeData: [{
        title: '더 <span class="text-orange-500">작고,</span> <br class="pc:block tablet:hidden mobile:hidden" />더 <span class="text-orange-500">빠르게</span>',
        desc: '우리는 핵심 재미를 담은 게임을 MVP로 빠르게 만듭니다. 완벽한 게임을 만들기 위해 오랜 시간 고민하지 않습니다. 이러한 시도가 실패로 끝나도 다시 시도하면서, 유저가 재밌는 게임을 제작합니다.'
      }, {
        title: '리텐션 리텐션, <br class="pc:block tablet:hidden mobile:hidden" />오로지 <span class="text-orange-500">리텐션</span>',
        desc: '우리는 눈 감아도 생각날 정도로 재밌는 게임을 만듭니다. 오늘도 내일도 자꾸만 하고 싶은 게임을 만들기 위해, 우리는 ‘유저가 무엇을 재미있어 할지’부터 ‘어떻게 해야 유저가 더 재밌게 게임을 플레이할 수 있을지’를 고민합니다. '
      }, {
        title: '<span class="text-orange-500">직관</span>에서 시작해, <br class="pc:block tablet:hidden mobile:hidden" /><span class="text-orange-500">데이터</span>로 마무리',
        desc: '우리는 직관에 의존하지 않고, 데이터와의 균형을 찾습니다. ‘재밌을 것 같은데?’라는 직관으로 게임을 만들 때도, 결과를 데이터로 분석하며 실패의 원인과 성공의 이유를 분석합니다.'
      }, {
        title: '만들 때는, <br class="pc:block tablet:hidden mobile:hidden" />솔플보다 <span class="text-orange-500">팀플</span>',
        desc: '탱커, 딜러, 힐러의 합이 맞아야 승리할 수 있는 게임처럼, 우리는 서로 다른 역량을 지닌 동료들과 힘을 합쳐 성공적인 게임을 만듭니다. 그리고 함께 만든 게임이 성공했을 때, 이 짜릿한 성취를 동료와 나눕니다.'
      }, {
        title: '<span class="text-orange-500">지식</span>과 <span class="text-orange-500">경험</span>은 항상 티키타카',
        desc: '우리는 지식과 경험을 끊임없이 공유합니다. ‘혹시 이거 아세요?’하고 먼저 물어보고 ‘이거 알아요!’라며 앞장서서 알려 줍니다. 지식과 경험을 티키타카 하며 주고받을 때, 우리는 함께 성장할 수 있다고 믿습니다.'
      }, {
        title: '모든 시도를 <br class="pc:block tablet:hidden mobile:hidden" /><span class="text-orange-500">회고</span>로 끝맺기',
        desc: '우리는 어떤 시도했는지 되돌아보고, 어떤 배움이 있었는지 반드시 확인합니다. 이렇게 모든 시도를 빠짐없이 회고하며 나와 우리를 위한 인사이트를 축적해 나갑니다.'
      }]
    };
  },
  methods: {

  },
};
</script> 
<style>

</style>
